.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-expansion-indicator::after {
  border-color: #144766;
}

.hrOpen {
  width: 80.5%;
  margin-left: 3px;
  align-self: flex-end;
  margin-right: 25px;
}

@media (max-width: 1280px) {
  .hrOpen {
    width: 72.2%;
  }
}

.hrClose {
  width: 89%;
  margin-left: 3px;
  align-self: flex-end;
  margin-right: 25px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #144766;
  --mdc-radio-selected-hover-icon-color: #144766;
  --mdc-radio-selected-icon-color: #144766;
  --mdc-radio-selected-pressed-icon-color: #144766;
  --mat-mdc-radio-checked-ripple-color: #144766;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #144766;
  --mdc-checkbox-selected-hover-icon-color: #144766;
  --mdc-checkbox-selected-icon-color: #144766;
  --mdc-checkbox-selected-pressed-icon-color: #144766;
  --mdc-checkbox-unselected-focus-icon-color: #144766;
  --mdc-checkbox-unselected-hover-icon-color: #144766;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #144766;
  width: 100%;
  margin: 2rem auto;
}

.mat-mdc-progress-spinner.white {
  --mdc-circular-progress-active-indicator-color: #fff;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #144766;
  --mdc-switch-selected-handle-color: #144766;
  --mdc-switch-selected-hover-state-layer-color: #144766;
  --mdc-switch-selected-pressed-state-layer-color: #144766;
  --mdc-switch-selected-focus-handle-color: #144766;
  --mdc-switch-selected-hover-handle-color: #144766;
  --mdc-switch-selected-pressed-handle-color: #144766;
  --mdc-switch-selected-focus-track-color: #668295;
  --mdc-switch-selected-hover-track-color: #668295;
  --mdc-switch-selected-pressed-track-color: #668295;
  --mdc-switch-selected-track-color: #668295;
}

.mat-horizontal-stepper-header {
  padding: 0px 12px !important;
}

.mat-horizontal-content-container {
  padding: 0px !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background: #144766;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}

.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #c2d1dc;
  --mdc-chip-label-text-color: #144766;
}
